// external
import { Box, Link, Typography, useTheme } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// internal
import { getAgreementNames } from 'utils';

import { getSubDomain } from '@guider-global/front-end-utils';

// Hooks
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useOrganization,
  useSettings,
} from '@guider-global/sanity-hooks';
import { Markdown } from 'components/Markdown';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { toggleModal } from 'store/slices/appSlice';
let { version: REACT_APP_VERSION } = require('../../../package.json');

export const Footer: React.FC = () => {
  // Utils
  const organizationSlug = getSubDomain();
  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  // Redux
  const dispatch = useAppDispatch();

  // Hooks
  const { pathname } = useLocation();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const { organization } = useOrganization({
    organizationSlug,
  });
  const additionalAgreements =
    organization?.white_label?.additional_agreements ?? [];

  const footerText = organization?.white_label?.footer_text ?? '';

  // Settings

  const { settings } = useSettings({
    localeCode,
  });

  // BaseLanguage Derivations
  const baseLanguageLegalNotices = baseLanguage?.legal_notices;

  const cookiesPolicy = baseLanguageLegalNotices?.cookie_policy;
  const companyInformation = baseLanguageLegalNotices?.company_information;
  const privacyPolicy = baseLanguageLegalNotices?.privacy_policy;

  const termsOfService = baseLanguageLegalNotices?.terms_of_service;

  const getFooterLogo = useCallback(() => {
    if (!settings) return;
    if (!settings?.static_media?.common?.footer_logo?._type) return;

    const imageSource = buildSanityImageUrl({
      source: settings?.static_media?.common?.footer_logo,
      format: 'svg',
    });

    return <img src={imageSource} alt="Powered by Guider" />;
  }, [settings]);

  if (pathname.includes('relationships')) {
    return <></>;
  }
  if (pathname.includes('video')) {
    return <></>;
  }
  if (pathname.includes('metrics')) {
    return <></>;
  }

  const linkStyles = {
    m: 1,
    color: 'text.secondary',
    textDecorationColor: theme.palette.text.secondary,
    fontWeight: 400,
    '&:hover': {
      color: 'text.primary',
      textDecorationColor: theme.palette.text.primary,
    },
  };

  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '242px',
        backgroundColor: '#F9FAFB',
        zIndex: 9,
      }}
    >
      {footerText && (
        <Typography
          component={'span'}
          variant="body1"
          textAlign="center"
          color={'text.secondary'}
          sx={{ width: '90%', mt: 6, fontWeight: '400' }}
        >
          <Markdown>{footerText}</Markdown>
        </Typography>
      )}

      <Link
        sx={{ mt: footerText ? 3 : 6, mb: 3 }}
        href="https://guider-ai.com/"
        target="_blank"
        rel="noreferer noopener"
      >
        {getFooterLogo()}
      </Link>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link
          component={RouterLink}
          to="/legal/cookies"
          target="_blank"
          sx={linkStyles}
          variant="body1"
        >
          {cookiesPolicy?.cookie_policy_label}
        </Link>
        <Link
          component={RouterLink}
          to="/legal/terms"
          target="_blank"
          sx={linkStyles}
          variant="body1"
        >
          {termsOfService?.terms_of_service_label ?? ''}
        </Link>
        <Link
          component={RouterLink}
          to="/legal/privacy"
          target="_blank"
          sx={linkStyles}
          variant="body1"
        >
          {privacyPolicy?.privacy_policy_label}
        </Link>
        {additionalAgreements.map((agreement) => {
          const {
            name,
            agreement_name: { current },
            _key,
          } = agreement;

          const { customCheckboxName } = getAgreementNames({
            name: current,
            id: _key,
          });
          return (
            <Link
              key={name}
              component={'button'}
              sx={linkStyles}
              onClick={() => dispatch(toggleModal(customCheckboxName))}
              variant={'body1'}
            >
              {name}
            </Link>
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          width: '100%',
        }}
        id="app-version"
        data-cy="components_Footer_app-version"
      >
        <Typography
          variant="body2"
          textAlign="center"
          color="text.secondary"
          sx={{ width: '90%' }}
        >
          {companyInformation}
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          color="text.disabled"
          sx={{ mt: 1, mb: 2 }}
        >
          v{REACT_APP_VERSION}
        </Typography>
      </Box>
    </Box>
  );
};
