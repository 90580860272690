// external
import LocationOn from '@mui/icons-material/LocationOn';
import Star from '@mui/icons-material/Star';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Card,
  Chip,
  ChipProps,
  Link,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

// internal
import {
  ICustomField,
  IMatchMembership,
  IProfile,
} from '@guider-global/shared-types';
import { LinkedInIcon } from '@guider-global/ui';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { hexToRGB } from 'utils';

// components
import { MDIcons, SanityIcon } from 'components';

// store
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { interpolate } from 'functions';
import { IGroupGuideForm } from 'store/slices/formsSlice';

interface GroupPreviewProps {
  programSlug: string;
  userRole: string | undefined;
  profileData: {
    profile: Partial<IProfile>;
    membership: Partial<IMatchMembership>;
  };
  groupData: IGroupGuideForm;
  showBestMatch?: boolean;
  action?: () => void;
  isLoading?: boolean;
  sxProps?: SxProps<Theme>;
  traineeProfiles?: string[];
}

export const GroupPreview: React.FC<GroupPreviewProps> = ({
  programSlug,
  profileData,
  groupData,
  showBestMatch = true,
  userRole,
  action,
  isLoading,
  sxProps = {},
  traineeProfiles,
}) => {
  const { getProfileImage } = useProfileImage();
  const organizationSlug = getSubDomain();
  // style
  const theme = useTheme();
  const { palette } = theme;
  const isMobile = useMobileMediaQuery();
  const chipProps: Partial<ChipProps> = {
    size: 'small',
    sx: {
      backgroundColor: `rgba(${hexToRGB(palette.info.main)}, 0.08)`,
      mr: 1,
      mb: 1,
    },
  };

  const { organization } = useOrganization({
    organizationSlug,
  });

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const linkedInButtonLabel =
    baseLanguage?.programs?.program_registration?.linkedin_profile_button_label;

  const isLinkedInEnabled =
    organization?.personal_details?.linkedin_url?.enabled ?? false;

  const profileFields = getVisibleProfileFields(
    organization?.profile_fields,
    profileData.profile.organizationFields as ICustomField[],
  );

  // program
  const { programs } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });

  const program = programs.find(
    (program) => program.metadata.id.current === programSlug,
  );
  if (!program) {
    return <></>;
  }

  const isGuide = userRole === 'guide';
  const skillsConfig = program.registration?.skills;
  const isSkillsEnabled = skillsConfig?.enable_skills_question;
  const maxSpots = program.relationships.group_settings?.max_trainees ?? 0;
  const spotsOccupied = traineeProfiles?.length ?? 0;
  const spotsLeft = maxSpots - spotsOccupied;
  //program type
  const programType = program.program_details?.program_type;
  const groupCopy = programType?.program_type_text?.variations?.group;
  const maxSpotsLabel: string =
    groupCopy?.registration?.profile_card?.max_spots_group_label ?? '';
  const spotsLeftLabel: string =
    groupCopy?.registration?.profile_card?.spots_left_group_label ?? '';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        ...sxProps,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Box sx={{ width: '100%', ...(isMobile ? { pb: 2 } : { pr: 2 }) }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {showBestMatch && (
              <Card
                variant="outlined"
                sx={{
                  backgroundColor: palette.success.main,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  py: 0.5,
                  px: 1,
                  mb: 1,
                  border: 'none',
                }}
              >
                <Star sx={{ mr: 1, color: 'white', fontSize: '14px' }} />
                <Typography sx={{ color: 'white', fontSize: '14px' }}>
                  {
                    baseLanguage?.programs?.program_registration
                      ?.best_match_label
                  }
                </Typography>
              </Card>
            )}
            <Typography variant="h5" sx={{ mb: 1 }}>
              {groupData.title}
            </Typography>
            <Typography variant="overline" sx={{ mb: 1 }}>
              {isGuide
                ? interpolate(maxSpotsLabel, { maxSpots: maxSpots.toString() })
                : interpolate(spotsLeftLabel, {
                    spotsLeft: spotsLeft.toString(),
                  })}
            </Typography>
            {isSkillsEnabled && groupData.skills && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                {groupData.skills.map((skill, index) => (
                  <Chip
                    key={`skills-chip-${skill}-${index}`}
                    label={
                      skillsConfig?.options?.find(
                        (option) => option.id.current === skill,
                      )?.label
                    }
                    {...chipProps}
                  />
                ))}
              </Box>
            )}
            <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
              {groupData.description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: isMobile ? '100%' : '200px',
            minWidth: isMobile ? '100%' : '200px',
            ...(isMobile
              ? { borderTop: `1px solid ${palette.divider}`, pt: 2 }
              : { borderLeft: `1px solid ${palette.divider}`, pl: 2 }),
          }}
        >
          <Typography
            variant="overline"
            color="text.secondary"
            sx={{ mb: 1.5 }}
          >
            {groupCopy?.registration?.profile_card?.meet_your_guide_label}
          </Typography>

          <Box
            sx={{
              ...(isMobile
                ? {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }
                : {}),
            }}
          >
            <Avatar
              src={getProfileImage({
                profilePicture: profileData?.profile.picture,
                userPicture: profileData?.membership.userPicture,
              })}
              sx={{
                width: '46px',
                height: '46px',
                backgroundColor: 'white',
                ...(isMobile ? { mr: 0.75 } : {}),
                minWidth: '46px',
              }}
              variant="rounded"
            />
            <Box>
              <Typography variant="body1" sx={{ mt: 0.75, mb: 0.25 }}>
                {profileData.profile.displayName}
              </Typography>
              {profileData.profile.jobTitle && (
                <Typography variant="caption" color="text.secondary">
                  {profileData.profile.jobTitle}
                </Typography>
              )}
              {profileData.profile.townOrCity && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 1,
                  }}
                >
                  <LocationOn color="info" sx={{ fontSize: 20, mr: 0.5 }} />
                  <Typography variant="caption" color="text.secondary">
                    {profileData.profile.townOrCity}
                  </Typography>
                </Box>
              )}
              {profileFields?.map((field, index) => {
                if (field?.label) {
                  return (
                    <Box
                      key={`preview-profile-field-${index}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          color: theme.palette.info.main,
                          fontSize: 20,
                          p: 0,
                          mr: 0.5,
                        }}
                      >
                        <SanityIcon name={field?.icon?.name as MDIcons} />
                      </Box>
                      <Typography variant="caption" color="text.secondary">
                        {field.label}
                      </Typography>
                    </Box>
                  );
                }
                return undefined;
              })}
              {isLinkedInEnabled && profileData.profile.linkedInUrl && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 1,
                  }}
                >
                  <LinkedInIcon
                    viewBox="0 0 15 15"
                    sx={{ fontSize: 15, mr: 1 }}
                  />
                  <Link
                    href={
                      profileData.profile.linkedInUrl.includes('http')
                        ? profileData.profile.linkedInUrl
                        : `http://${profileData.profile.linkedInUrl}`
                    }
                    color="info.main"
                    underline="none"
                    variant="caption"
                    sx={{
                      ':hover': {
                        color: 'info.dark',
                      },
                    }}
                    target="_blank"
                  >
                    {linkedInButtonLabel}
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <LoadingButton
        data-cy="components_Guide_GuideCard_connect-button"
        variant="contained"
        color="info"
        sx={{ mt: 3 }}
        onClick={action}
        disabled={!action}
        loading={isLoading}
        fullWidth
      >
        {groupCopy?.registration?.profile_card?.join_group_button_label}
      </LoadingButton>
    </Box>
  );
};
