// external
import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

// components
import { CustomChatComposite } from './CustomChatComposite';

// hooks
import { useRelationships } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';
import { useLocalization } from 'hooks';

export type ChatComponentProps = {
  relationship: IRelationship | undefined;
  isGuide: boolean;
  isGroupProgram: boolean;
  isNotLiveGroup: boolean;
  threadId?: string;
  archived?: boolean;
  onSendMessage?: () => Promise<void>;
};

export const Chat: React.FC<ChatComponentProps> = ({
  relationship,
  isGuide,
  isGroupProgram,
  isNotLiveGroup,
  threadId,
  archived,
  onSendMessage,
}) => {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const archivedLabel =
    baseLanguage?.relationships?.archived_relationship_view
      ?.relationship_archived_message;

  const { reqRelationships, isLoadingRelationships } = useRelationships({});

  const isReady = !!threadId;

  useEffect(() => {
    const shouldPollRelationships = relationship && !threadId;
    if (!shouldPollRelationships) {
      return () => {};
    }

    const intervalId = setInterval(() => {
      if (isLoadingRelationships) return;
      reqRelationships({
        method: 'GET',
        url: '/relationships',
      });
    }, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, [isLoadingRelationships, relationship, reqRelationships, threadId]);

  if (!isReady) {
    return <CircularProgress color="secondary" size={120} />;
  }

  return (
    <CustomChatComposite
      relationship={relationship}
      isGuide={isGuide}
      isGroupProgram={isGroupProgram}
      isNotLiveGroup={isNotLiveGroup}
      threadId={threadId}
      archived={archived}
      archivedLabel={archivedLabel}
      onSendMessage={onSendMessage}
    />
  );
};
